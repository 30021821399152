var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.userIsEmployer)?_c('v-container',[_c('MInstruction',{attrs:{"instruction":{
        boilerInstructions: [
          'Enter in publically-editable Miro boards for hackathon participants to use',
          'Each entry should have team name and Miro board URL'
        ],
        goal: 'Facilitate problem-solving between team members of hackathon participants '
      }}}),_c('h3',{staticStyle:{"margin-bottom":"1.2rem"}},[_vm._v("Team Boards")]),_vm._l((_vm.organizerData.boards),function(ref){
      var teamName = ref.teamName;
      var url = ref.url;
return _c('div',[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"flex-start","gap":"1.2rem"}},[_c('v-text-field',{staticStyle:{"padding":"0"},attrs:{"outlined":"","rounded":"","value":teamName,"readonly":""}}),_c('v-text-field',{staticStyle:{"padding":"0"},attrs:{"outlined":"","rounded":"","value":url,"readonly":""}}),_c('v-btn',{attrs:{"x-large":"","rounded":"","outlined":"","size":"x-large","color":"error"},on:{"click":function($event){_vm.organizerData.boards = _vm.organizerData.boards.filter(
              function (x) { return x.teamName !== teamName || x.url !== url; }
            );
            _vm.$emit('save-progress');}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)],1)])}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"flex-start","gap":"1.2rem"}},[_c('v-text-field',{staticStyle:{"padding":"0"},attrs:{"label":"Team Name","outlined":"","rounded":""},model:{value:(_vm.nextTeamName),callback:function ($$v) {_vm.nextTeamName=$$v},expression:"nextTeamName"}}),_c('validation-provider',{attrs:{"rules":{ regex: _vm.urlRegex, required: false }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"padding":"0"},attrs:{"label":"Board URL","outlined":"","rounded":"","error-messages":errors},model:{value:(_vm.nextBoardUrl),callback:function ($$v) {_vm.nextBoardUrl=$$v},expression:"nextBoardUrl"}})]}}],null,false,1153008985)}),_c('v-btn',{attrs:{"disabled":_vm.nextTeamName === '' || _vm.nextBoardUrl === '',"x-large":"","rounded":"","outlined":"","size":"x-large"},on:{"click":_vm.addBoard}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],2):_c('v-container',[_c('MInstruction',{attrs:{"instruction":{
        boilerInstructions: [
          'Go to your team\'s assigned Miro board',
          'Collaborate the board however you wish'
        ],
        goal: 'Solve problems with your team'
      }}}),_c('h2',{staticStyle:{"margin-bottom":"1.2rem","text-align":"center"}},[_vm._v("Team Boards")]),_vm._l((_vm.organizerData.boards),function(ref){
      var teamName = ref.teamName;
      var url = ref.url;
return _c('div',[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","gap":"1.2rem","margin-bottom":"0.8rem"}},[_c('v-btn',{staticStyle:{"flex":"1","background-color":"#ffe354","font-weight":"bold"},attrs:{"href":url,"rounded":"","x-large":"","target":"_blank","elevation":"0"}},[_vm._v(" Go To "+_vm._s(teamName)+" Board "),_c('v-icon',[_vm._v("mdi-open-in-new")])],1)],1)])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }