
import { defineComponent, ref, computed } from '@vue/composition-api';
import MInstruction from '../../molecules/m-instruction.vue';
import { Field } from '../index';

interface Board {
  teamName: string;
  url: string;
}
interface OrganizerSetupOKR {
  boards: Board[];
}
interface ParticipantOKR {}
interface OKRField extends Field {
  value: ParticipantOKR;
  setup: OrganizerSetupOKR;
}

export default defineComponent({
  name: 'MSolve',
  components: {
    MInstruction
  },
  props: {
    fetchProgram: {
      required: false,
      type: [Object, Function],
      default: () => {}
    },
    adkType: {
      required: false,
      type: String,
      default: ''
    },
    userType: {
      required: true,
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(_props, _ctx) {
    const programDocument: any = computed({
      get: () => _props.value,
      set: newVal => {
        _ctx.emit('input', newVal);
      }
    });

    const adkIndex = computed(() => {
      const indx = programDocument.value?.data.adks?.findIndex(adk => {
        if (adk) {
          return adk.name === 'Solve';
        }
        return false;
      });
      return indx;
    });

    const fieldIndex = computed(() => {
      const Fdindx = programDocument.value?.data?.adks?.[adkIndex.value]?.fields?.findIndex(
        field => {
          if (field.name) {
            return field.name === 'm-solve';
          }
          return false;
        }
      );
      return Fdindx;
    });

    const fieldValue = computed<OKRField>({
      get: () => programDocument.value?.data?.adks?.[adkIndex.value]?.fields[fieldIndex.value],
      set: newVal => {
        if (fieldValue.value)
          programDocument.value.data.adks[adkIndex.value].fields[fieldIndex.value] = newVal;
      }
    });

    const userTypeValue = computed(() => {
      if (
        _props.userType === 'organizer' &&
        _ctx.root.$route.name !== 'Monitor' &&
        !_props.isPreview
      ) {
        return 'organizer';
      }
      if (_props.isPreview) return 'participant';
      if (_props.isVisitor) return 'organizer';
      return 'participant';
    });

    const userIsEmployer = ref(userTypeValue.value === 'organizer');

    const organizerData = computed<OrganizerSetupOKR>({
      get: () => fieldValue.value.setup,
      set: newVal => {
        fieldValue.value.setup = newVal;
      }
    });

    const participantData = computed<ParticipantOKR>({
      get: () => fieldValue.value.value,
      set: newVal => {
        fieldValue.value.value = newVal;
      }
    });

    const nextTeamName = ref('');
    const nextBoardUrl = ref('');

    const addBoard = () => {
      organizerData.value.boards.push({ teamName: nextTeamName.value, url: nextBoardUrl.value });
      nextTeamName.value = '';
      nextBoardUrl.value = '';
      _ctx.emit('save-progress');
    };

    const urlRegex =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/;

    return {
      organizerData,
      participantData,
      userIsEmployer,
      nextTeamName,
      nextBoardUrl,
      addBoard,
      urlRegex
    };
  }
});
